.link {
  font-size: 21px;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
}

.link-wrapper {
  margin-top: 55px;
}

.hero {
  background: url('../../../public/assets/welcome-image.svg') bottom right no-repeat;
  background-size: 211px 211px;
  height: 300px;
}

.hero > p {
  text-align: left;
  font-size: 41px;
  color: #fff;
  line-height: 55px;
  font-weight: 700;
}

.hero > img {
  width: 211px;
  height: 211px;
}

.appinfo-wrapper {
  display: flex;
  margin-top: 30px;
  align-items: center;
}

.appinfo-wrapper > img {
  width: 70px;
  height: 70px;
  border-radius: 8px;
}

.appinfo-wrapper > p {
  margin-left: 20px;
  font-size: 21px;
  color: #fff;
  font-weight: 700;
}