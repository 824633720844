.hero-img {
  margin-top: 40px;
}

.hero-img > img {
  width: 300px
}

.community-hero {
  margin-top: 40px;
  color: #fff;
}

.community-hero-text {
  margin: 40px 0;
}

.community-hero-sub-text {
  font-size: 15px;
  color: #fff;
}

.section-header {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}